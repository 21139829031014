<template>
  <div class="page event-page">
    <div class="row text">
      <div  v-if="$store.state.page.content.pageimage.value" class="col is-3 is-offset-1 is-no-offset-sm">
        <image-elem :file="$store.state.page.content.pageimage.value" size="events.detail"></image-elem>
      </div>
      <div class="col is-7 is-9-sm">
        <p class="eventtype">
          {{ $store.state.page.content.eventtype.value }}
          <span v-if="bookingSold" class="sold">ausgebucht</span>
        </p>
        <h1>{{ $store.state.page.content.title.value }}</h1>
        <h2 v-if="$store.state.page.content.subheadline.value">{{ $store.state.page.content.subheadline.value }}</h2>
        <p v-if="isSingle" class="datetime">
          <span class="date">{{ $store.state.page.content.date.value | date_weekday }}</span> {{ $store.state.page.content.time.value | time }}
        </p>
        <p v-if="isWorkshop" class="datetime">
          <span class="date">{{ $store.state.page.content.date.value | date_weekday }} bis {{ $store.state.page.content.dateend.value | date_weekday }}</span>
        </p>
        <p v-if="isCourse" class="datetime">
          <span class="date">{{ $store.state.page.content.datescount.value }} x {{ weekdays }} {{ $store.state.page.content.time.value | time }}</span><br />
          {{ $store.state.page.content.date.value | date_weekday }} bis {{ $store.state.page.content.dateend.value | date_weekday }}
        </p>
        <p class="speaker">mit {{ $store.state.page.content.speaker.value }}</p>
      </div>
    </div>
    <div v-if="hasCopy" class="row text">
      <div class="col is-10 is-12-sm is-offset-1 is-no-offset-sm">
        <hr />
        <text-elem :text="$store.state.page.content.copy"></text-elem>
      </div>
    </div>
    <div class="row text">
      <div class="col is-10 is-12-sm is-offset-1 is-no-offset-sm">
        <hr />
      </div>
    </div>
    <div class="row is-multiline text">
      <div class="col is-3 is-12-sm is-offset-1 is-no-offset-sm label">Preis</div>
      <div class="col is-7 is-12-sm value">{{ prices }}</div>
    </div>
    <div v-if="hasServices" class="row is-multiline text">
      <div class="col is-3 is-12-sm is-offset-1 is-no-offset-sm label">Hinweise</div>
      <div class="col is-7 is-12-sm value">{{ services }}</div>
    </div>
    <div class="row is-multiline text">
      <div class="col is-3 is-12-sm is-offset-1 is-no-offset-sm label">Anmeldung</div>
      <div class="col is-7 is-12-sm value booking">
        <span v-if="bookingNone" class="none">nicht erforderlich</span>
        <span v-if="bookingSold" class="sold">ausgebucht</span>
        <span v-if="bookingBoxoffice" class="boxoffice">vor Ort am Tag der Veranstaltung</span>
        <link-elem v-if="bookingMail" :link="$store.state.page.content.bookingmail">per E-Mail</link-elem>
        <link-elem v-if="bookingLink" :link="$store.state.page.content.bookinglink">über diese Webseite</link-elem>
      </div>
    </div>
    <div class="row is-multiline text">
      <div class="col is-3 is-12-sm is-offset-1 is-no-offset-sm label">
        Veranstalter
        <span v-if="$store.state.page.content.website.value"> und weitere Info</span>
      </div>
      <div class="col is-7 is-12-sm value">
        <link-elem
          v-if="$store.state.page.content.website.value"
          :link="$store.state.page.content.website">
            {{ $store.state.page.content.organizer.value }}
        </link-elem>
        <span v-else>{{ $store.state.page.content.organizer.value }}</span>
      </div>
    </div>
    <div v-if="hasFiles" class="row is-multiline text">
      <div class="col is-3 is-12-sm is-offset-1 is-no-offset-sm label">
        Dateien zum Download
      </div>
      <div class="col is-7 is-12-sm value">
        <links-elem :links="$store.state.page.content.files.value"></links-elem>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'event-page',
  computed: {
    isSingle () {
      return this.$store.state.page.head.blueprint === 'event_single'
    },
    isWorkshop () {
      return this.$store.state.page.head.blueprint === 'event_workshop'
    },
    isCourse () {
      return this.$store.state.page.head.blueprint === 'event_course'
    },
    hasCopy() {
      return this.$store.state.page.content.copy.value.length > 0
    },
    hasServices () {
      return this.$store.state.page.content.services.value.length > 0
    },
    hasFiles () {
      return this.$store.state.page.content.files.value.length > 0
    },
    bookingNone () {
      return this.$store.state.page.content.booking.value === 'none'
    },
    bookingSold () {
      return this.$store.state.page.content.booking.value === 'sold'
    },
    bookingBoxoffice () {
      return this.$store.state.page.content.booking.value === 'required' &&
        fn.isTrue(this.$store.state.page.content.bookingboxoffice.value)
    },
    bookingMail () {
      return this.$store.state.page.content.booking.value === 'required' &&
        fn.isEmail(this.$store.state.page.content.bookingmail.value)
    },
    bookingLink () {
      return this.$store.state.page.content.booking.value === 'required' &&
        fn.isString(this.$store.state.page.content.bookinglink.value, 1)
    },
    weekdays () {
      return this.$store.state.page.content.weekdays.value.join(', ')
    },
    prices () {
      var res = []
      fn.each(this.$store.state.page.content.prices.value, (paragraph) => {
        res.push(paragraph.html)
      })
      return res.join(' ')
    },
    services () {
      var res = []
      fn.each(this.$store.state.page.content.services.value, (paragraph) => {
        res.push(paragraph.html)
      })
      return res.join(' ')
    }
  },
  mounted() {
    //log(this.$store.state.page.content)
  }
}
</script>

<style lang="sass">
.event-page
  .text
    .eventtype
      margin-bottom: m(1)
      text-transform: uppercase
      +font('bold', 'small')
      .sold
        margin-left: m(1)
        padding: 0px 6px
        line-height: 1
        color: $white
        background-color: $warning-color
        border-radius: $radius
    h1
      margin-top: 0
      margin-bottom: 0
    h2
      margin-top: 0
    .datetime
      margin-top: m(1)
      .date
        +font('medium')
    .speaker
      margin-top: m(1)
    .label
       color: $grey-light
    .booking
      .sold
        +font('bold')
      .boxoffice,
      .link
        &:after
          content: " | "
          color: $text-color
        &:last-child
          &:after
            content: ""

+sm
  .event-page
    .text
      .label
        padding-bottom: 0
      .value
        padding-top: 0

</style>  